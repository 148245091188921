import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../pages/assets/logo new colours.png";

export default function Header() {
  const { click, setClick } = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <nav className="navbar navbar-expand-lg bg-light">
    <div>
      <div className="nav-container navbar justify-content-space-between" >
        <div className="  d-flex flex-row" style={{padding:'8vw'}}>
          <Link to="/">
            <img
              style={{ width: "356px", height: "59px" }}
              src={logo}
              alt="Logo"
            />
          </Link>
        
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav helv fw-bold" style={{marginTop:'12px'}}>
                <li className="nav-item">
                  <Link className="nav-link active" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link " to="/technology">
                  Technology
                  </Link>
                </li>
                
                <li className="nav-item">
                  <Link className="nav-link " to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
              <div className=" menue-icon" onClick={handleClick}>
                <i className={click ? "fas fa-time" : "fas fa-bars"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </nav> 

  );
}
