import React, { useEffect } from "react";
import $ from "jquery";
import { BsFillHexagonFill } from "react-icons/bs";

import { Link } from "react-router-dom";

import ScrollToTop from "./Scroll";

export default function ABOUT() {
  // useEffect(
  //   () =>
  //     function () {
  //       $(
  //         "#visiontext, #missiontext, #solutiontext, #uniquevaluetext, #endproducttext, #impacttext"
  //       ).hide();
  //       $("#impact")
  //         .on("mouseenter", function () {
  //           $("#impacthex").stop().css("color", "white");
  //           $("#impacttext").stop().fadeIn(150);
  //         })
  //         .on("mouseleave", function () {
  //           $("#impacthex").stop().css("color", "rgb(251,147,52)");
  //           $("#impacttext").stop().fadeOut(150);
  //         });
  //       $("#endproduct")
  //         .on("mouseenter", function () {
  //           $("#endproducthex").stop().css("color", "white");
  //           $("#endproducttext").stop().fadeIn(150);
  //         })
  //         .on("mouseleave", function () {
  //           $("#endproducthex").stop().css("color", "rgb(251,147,52)");
  //           $("#endproducttext").stop().fadeOut(150);
  //         });
  //       $("#vision")
  //         .on("mouseenter", function () {
  //           $("#visionhex").stop().css("color", "white");
  //           $("#visiontext").stop().fadeIn(150);
  //         })
  //         .on("mouseleave", function () {
  //           $("#visionhex").stop().css("color", "rgb(251,147,52)");
  //           $("#visiontext").stop().fadeOut(150);
  //         });
  //       $("#uniquevalue")
  //         .on("mouseenter", function () {
  //           $("#uniquevaluehex").stop().css("color", "white");
  //           $("#uniquevaluetext").stop().fadeIn(150);
  //         })
  //         .on("mouseleave", function () {
  //           $("#uniquevaluehex").stop().css("color", "rgb(251,147,52)");
  //           $("#uniquevaluetext").stop().fadeOut(150);
  //         });
  //       $("#mission")
  //         .on("mouseenter", function () {
  //           $("#missionhex").stop().css("color", "white");
  //           $("#missiontext").stop().fadeIn(150);
  //         })
  //         .on("mouseleave", function () {
  //           $("#missionhex").stop().css("color", "rgb(251,147,52)");
  //           $("#missiontext").stop().fadeOut(150);
  //         });
  //       $("#solution")
  //         .on("mouseenter", function () {
  //           $("#solutionhex").stop().css("color", "white");
  //           $("#solutiontext").stop().fadeIn(150);
  //         })
  //         .on("mouseleave", function () {
  //           $("#solutionhex").stop().css("color", "rgb(251,147,52)");
  //           $("#solutiontext").stop().fadeOut(150);
  //         });
  //     }
  // );
  return (
    <><ScrollToTop /><div>
      {/* TOP IMAGE */}
      <div className="non-home-background-container">
        <img
          src={require("./assets/Lions head4.jpg")}
          style={{ width: "100%", height: "965px", objectFit: "cover" }}
          alt="home_background" />
      </div>
      <div className="container-fluid tda p-0 d-flex flex-column align-items-center">
        {/* ABOUT US SECTION */}
        <img
          id="larvaefeeding"
          src={require("./assets/larvae feeding.png")}
          style={{ borderRadius: "0px 0px 20px 20px" }}
          alt="larvae feeding" />
        <div className="d-flex flex-column container-fluid align-items-center"
          style={{
            height: "1650px",
            paddingTop: "2vw",
            paddingLeft: "0",
            paddingRight: "0",
          }}
        >
          <h1 className="lulo gre text-center" style={{ marginBottom: "2vw" }}>
            ABOUT US
          </h1>

          <div className="d-flex container-fluid justify-content-start align-items-center p-0">
            <div
              className="abouthighlight tli d-flex justify-content-end"
              style={{
                width: "40%",
                zIndex: "0",
                left: "0",
                borderRadius: "0 20px 20px 0",
              }}
            >
              <p
                className="whi helv"
                style={{ textAlign: "left", marginLeft: "32%", zIndex: "1" }}
              >
                <br></br><br></br>ukuChaCha safely returns toilet resources to the land, placing it alongside animal manures, in its rightful place. 
                There is no place for sludge in our rivers, oceans, and other water sources.<br></br><br></br><br></br>
              </p>
            </div>
            <img
              src={require("./assets/toilets inverted.png")}
              alt="toilets"
              style={{ width: "15vw", marginLeft: "130px" }} />
          </div>
          <div className="d-flex container-fluid justify-content-end align-items-center p-0">
            <img
              src={require("./assets/plants.png")}
              alt="agriculture"
              style={{ width: "15vw", marginRight: "10%" }} />
            <div
              className="abouthighlight tli d-flex justify-content-start"
              style={{
                width: "52.5%",
                zIndex: "0",
                right: "0",
                borderRadius: "20px 0 0 20px",
              }}
            >
              <p
                className="whi helv"
                style={{
                  textAlign: "right",
                  marginRight: "24%",
                  zIndex: "1",
                }}
              >
                Sludge nutrients are reconfigured through larval activity (enzymatic and biological heat transfer) into soil hydrolysates, 
                lipids, and soil carbon and are returned to the land in a safe and efficient way. This process leads to a reduction in the cost of the treatment of 
                sludge through reducing it volumetrically over a short period and through its further stabilisation and beneficiation for increased productivity in agriculture and reduced need to landfill.
              </p>
            </div>
          </div>
          <div
            className="d-flex container justify-content-center align-items-center"
            style={{ width: "100%", marginTop: "100px", marginBottom: "50px" }}
          >
            <p className="whi helv">
              ukuChaCha provides municipalities, and other utility and service providers with a turnkey, sludge diversion solution, that
              ensures that risky waste does not end up in water sources, returning nutrients to the soil where they belong.
            </p>
          </div>

          <h2 className="lulo gre text-center" style={{ marginBottom: "2vw", marginTop: "5vw" }}>
            10 years of sludge treatment experience
          </h2>
          <div class="row ">
            <div class="column">
              <img
                id="timeline"
                src={require("./assets/timeline.png")}
                alt="10 year timeline"
                style={{
                  height: "450px",
                  borderRadius: "1rem",

                }} />
            </div>


          </div>
        </div>
       
        <Link
          to="/contact"
          className="container-fluid d-flex justify-content-center"
        >
          <button className="button mb-5" style={{ width: "68vw" }}>
            GET IN TOUCH
          </button>
        </Link>
      </div>
      </div>
    </>
    
  );
}
