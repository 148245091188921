// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCzklCKL_F2vixRtz88fe6phzmoeXZyri8",
  authDomain: "ukuchacha-6e04c.firebaseapp.com",
  projectId: "ukuchacha-6e04c",
  storageBucket: "ukuchacha-6e04c.appspot.com",
  messagingSenderId: "199876709884",
  appId: "1:199876709884:web:8b80ab402d809813fdb679",
  measurementId: "G-56R3SK8H3W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export {
    firestore
}
