//test comment
import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/js/dist/collapse";
import "./App.css";

import Header from "./components/elements/Header/Header.js";
import Home from "./components/pages/Home.js";
import About from "./components/pages/About.js";
import Technology from "./components/pages/TECHNOLOGY.js";
import Contact from "./components/pages/Contact.js";
import Footer from "./components/elements/Footer/Footer.js";

import contactService from './services/contact_service';

export default function App() {
  
  const [contacts, setContacts] = useState([]);

  async function onContactCreated(contact) {
    
    try {
      contact = await contactService.createContact(contact)

      const newContacts = [];
      for (let c of contacts) { newContacts.push(c); }
      newContacts.push(contact);

      setContacts(newContacts);
    } catch(err) {
      console.log(err);
    }
  }


  return (
    <div className="page-container">
      <BrowserRouter>
        <Header></Header>

        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/technology" element={<Technology />}></Route>
          <Route path="/contact" element={<Contact onContactCreated={onContactCreated}></Contact>}></Route>
        </Routes>
        
        <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}
