import React, { useState } from "react";
import { BsChevronDown } from "react-icons/bs";
// import $ from "jquery";
import ScrollToTop from "./Scroll";

export default function HOME() {
  const [chevOpacity, setChevOpacity] = useState(0);

  function scrollFunc() {
    var y = window.pageYOffset;
    // console.log(y);
    if (y >= 100) {
      setChevOpacity((y - 100) * 0.01);
    } else {
      setChevOpacity(0);
    }
  }

  window.addEventListener("scroll", scrollFunc);

  return (
    <><ScrollToTop /><div>
      {/* TITLE IMAGE */}
      <div className="d-flex justify-content-center">
        <div
          style={{
            position: "absolute",
            marginTop: "150px",
            marginLeft: "10%",
            marginRight: "5%",
          }}
        >

{/* <h1 className="lulo whi">
            Nutrient
            <br />
            Recovery:
        </h1>;*/}

          <h2 className="lulo whi mt-5" style={{ marginBottom: "200px" }}>
          <br></br>The intersection of automation and insects in the Recovery of nutrients from risky waste
          </h2>
        {/*
        <h3 className="lulo mt-5" style={{ marginBottom: "100px" }}>
          Delivering safe and sustainable solutions for the disposal of higher-risk organics</h3>
          */}
          <BsChevronDown
            id="chevron"
            color="white"
            style={{ marginLeft: "12%", opacity: chevOpacity }} />
        </div>
        <img
          src={require("./assets/Lions head4.jpg")}
          style={{ width: "100%", height: "825px", objectFit: "cover" }}
          alt="home_background" />
      </div>
      {/* THE CHALLENGE */}
      <div id='thechallenge' className='container-fluid d-flex justify-content-center nav' style={{ paddingBottom: '0px', textAlign: 'center' }}>
        <h1 className='lulo whi' style={{ marginBottom: '0px' }}>Sludge facts</h1>
        <div className='container-fluid' style={{ position: 'relative', marginTop: '50px', width: '80%' }}>
          <div id='challenges' className='d-flex' style={{ marginBottom: '10px' }}>
            <div id='mow' className='col challengebox'>
              <h1 id='mowtext' className='tli helv'>
                01
              </h1>
              <h3 
                className='whi helv mb-4 mt-4' 
                style={{ textAlign: 'left' }}>
                  SEWERAGE<br />FLOWS
              </h3>
              <img 
                src={require('./assets/Niranjan Shrestha.png')} 
                style={{ width: '100%', borderRadius: '25px' }} 
                alt='mowimg'>
              </img>
              <p id='mowdesc' className='whi helv mt-4'>
              In most areas of the world, sewerage pollutes rivers, oceans, and other much-needed water sources. <br></br><br></br>
              It is, however, a valuable resource that, if treated correctly, could power, and feed our globe.
              </p>
            </div>
            <div id="eid" className="col challengebox d-flex flex-column align-items-start">
              <h1 id="eidtext" className="tli helv">
                02
              </h1>
              <h3 
                className="whi helv mb-4 mt-3" 
                style={{ textAlign: "left" }}>
                SLUDGE <br />IS EVERYWHERE
              </h3>
              <img
                src={require("./assets/Sewerage works.JPG")}
                style={{ width: "95%", borderRadius: "25px" }}
                alt="eidimg">
              </img>
              <p id="eiddesc" className="whi helv mt-3">
                <br></br>Many countries do not have the necessary physical infrastructure to maintain functioning sewer systems. 
                Alternatives are necessary.<br></br><br></br><br></br>

              </p>
            </div>
            <div id="eotb" className="col challengebox d-flex flex-column align-items-start">
              <h1 id="eotbtext" className="tli helv">
                03
              </h1>
              <h3 className="whi helv mb-4 mt-4" 
              style={{ textAlign: "left" }}>
              SLUDGE IS<br />HIGH-RISK
              </h3>
              <img
                src={require("./assets/chromatograph.jpg")}
                style={{ width: "100%", borderRadius: "25px" }}
                alt="eotbimg">
              </img>
              <p id="eotbdesc" className="whi helv mt-3">
              <br></br><br></br>Sludge is difficult and unpleasant to treat, and current approaches are characterised by "Out of sight, out of mind".<br></br><br></br><br></br>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* MEET THE BLACK SOLDIER FLY */}
      <div id='blacksoldierfly' className='container-fluid tda' style={{ textAlign: 'center' }}>
        <h1 className='lulo whi' style={{ marginBottom: '30px' }}>THE BLACK<br />SOLDIER FLY (BSF)</h1>
        <img id='fly' src={require('./assets/fly.png')} alt='fly' style={{ width: '30%' }} />
        <h2 className='whi helv fw-bold'>Converts complicated waste streams into valuable proteins &<br />fats for agriculture and fuel applications</h2>
        <a href='/technology'>
          <button type='button' className='button mt-5' style={{ width: '68vw' }}>LEARN MORE</button>
        </a>
      </div>
    </div></>
  );
}
