import React from "react";
import { MdQueryStats } from "react-icons/md";
import { BsCashCoin, BsSpeedometer2 } from "react-icons/bs";
import { AiOutlineCloudSync } from "react-icons/ai";
import { BiTimer } from "react-icons/bi";
import tgraphic from "./assets/waste cycle.png";
import tray from "./assets/Setting trays.jpeg";
import ScrollToTop from "./Scroll";


export default function TECHNOLOGY() {
  return (
    <><ScrollToTop /><div>
      {/* TOP IMAGE */}
      <div className="non-home-background-container">
        <img
          src={require("./assets/Lions head4.jpg")}
          style={{ width: "100%", height: "965px", objectFit: "cover" }}
          alt="home_background" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <div className="container-fluid d-flex flex-column align-items-center nav">
          <h1
            className="lulo gre"
            style={{ marginTop: "10px", marginBottom: "20px", fontSize: "45px", width:"95%", textAlign: "center", paddingTop: "10vh" }}
          >
            The Technology
         </h1>
          <h2 className="helv whi"
      style={{ fontSize: "30px", width: "80%"}}>
      We have chosen Nutrisek's Bioreactor Module as our technological solution: An Efficient, Automated and Turnkey waste conversion process that provides year round reliability for our waste partners.
    </h2><hr style={{ width: "68vw", marginTop: "30vh" }}></hr>
    
    <img
      id="infographic"
      src={require("./assets/HIVE 25.png")}
      alt="infographic"
      style={{ width: "50%", paddingTop: "2vh" }}
    ></img>
<p
            className="helv whi"
            style={{ marginBottom: "120px", width: "80%", fontSize: "32px" }}
          >   For every HIVE 25 plant in operation, we save 3000 tons of carbon
              per year, this is equivalent to 250 hectares of soya or 1800 tons of forage fish.
          </p>

        </div>

        <div className="container-fluid tda d-flex flex-column align-items-center">
          <h1
            className="lulo gre text-center"
            style={{ width: "65%", marginTop: "60px", marginBottom: "120px" }}
          >
            {" "}
            Deployment{" "}
          </h1>
          
          <p
            className="helv whi text-center"
            style={{ marginBottom: "150px", width: "80%", fontSize: "32px" }}
          >
            Their innovative hardware-as-a-service model allows customer peace of mind and a low barrier to entry
          </p>
          <div
            id="benefiticons"
            className="d-flex flex-wrap justify-content-around mt-4 mb-4"
          >
            <div
              id="boc"
              className="d-flex flex-column align-items-center"
              style={{ width: "175px" }}
            >
              <MdQueryStats id="bbsicon"></MdQueryStats>
              <p className="helv whi bold text-center">Better Balance Sheet</p>
            </div>
            <div
              id="bbs"
              className="d-flex flex-column align-items-center"
              style={{ width: "175px" }}
            >
              
              <BiTimer id="treicon"></BiTimer>
              <p className="helv whi bold text-center">Quick deployment
              </p>
            </div>
            <div
              id="tre"
              className="d-flex flex-column align-items-center"
              style={{ width: "175px" }}
            >
              <AiOutlineCloudSync id="ficmicon"></AiOutlineCloudSync>
              <p className="helv whi bold text-center">
                Long-term service level agreement
              </p>
            </div>
            <div
              id="ficm"
              className="d-flex flex-column align-items-center"
              style={{ width: "175px" }}
            >
              <BsCashCoin id="bocicon"></BsCashCoin>
              <p className="helv whi bold text-center">
                Buy back option on all larvae produced
              </p>
            </div>
            <div
              id="ror"
              className="d-flex flex-column align-items-center"
              style={{ width: "175px" }}
            >
              <BsSpeedometer2 id="roricon"></BsSpeedometer2>
              <p className="helv whi bold text-center">High ROI for customers</p>
            </div>
          </div>
        </div>

        <div className="container-fluid d-flex p-0 flex-wrap nav">
          <div style={{ position: "relative", width: "50%" }}>
            <img
              src={require("./assets/4-house-broiler2.jpg")}
              alt="facility"
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
            ></img>
          </div>
          <div
            className="container-fluid d-flex flex-column justify-content-center align-items-start"
            style={{ width: "50%", padding: "40px" }}
          >
            <h2 className="lulo whi">
              Want a bigger
              <br />
              facility?
            </h2>
            <p id="photopg" className="helv whi">
              Simply connect two, three, or more HIVE 25 bioreactors in parallel like a typical poulty farm.
              Process up to 150 tons per day of sludge, or start with one HIVE 25
              and grow your treatment potential one module at a time.
            </p>
          </div>
        </div>

        <div className="container-fluid d-flex p-0 flex-wrap nav">
          <div
            id="impactsection"
            className="container-fluid d-flex flex-column justify-content-center align-items-start"
            style={{paddingLeft:'15vw', paddingTop: '2vw'}}
          >
            <h2 className="lulo gre">Impact</h2>
            <p id="photopg" className="helv whi">
              Combined with other technologies like anaerobic digestion and
              aerobic composting, our-bug-based recycling approach extracts
              maximum economic and ecological value out of sludge for maximum
              impact.
            </p>
            
            <p className="helv gre font-italic" style={{ width: "65%" }}>
              POTENTIAL FOR CARBON CREDIT GENERATION IN CERTAIN INSTANCES
            </p>
          </div>
          <div style={{ position: "relative", width: "50%" }}>
            <img
              src={require("./assets/landscape mountain.jpg")}
              alt="mountains"
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
            ></img>
          </div>
        </div>
      </div>
    </div></>
  );
}
